import {
  Box,
  Button,
  Flex,
  HStack,
  Menu,
  MenuButtonProps,
  MenuItemProps,
  MenuListProps,
  Tag,
  Text,
} from '@televet/kibble-ui';
import partial from 'lodash-es/partial';
import { getSendConditionLabel } from 'pages/Automations/state/utils/getSendConditionLabel';
import {
  SendCondition,
  SendConditionAttribute,
  TaggableSendConditionAttribute,
} from 'pages/Automations/types/AutomationSendConditions';
import { PatientAgeUnit, SendConditionPatientAgeOperator } from 'pages/Automations/types/SendConditionPatientAge';
import React, { useCallback } from 'react';
import { ConditionAttribute, ConditionOperator } from 'shared/types/graphql';
import { AutomationTag, TagDisabledState } from '.';
import { PetAgeForm } from './PetAgeForm';

const patientAgeOperatorOptions = [
  { label: 'Younger', value: ConditionOperator.LessThan },
  { label: 'Older', value: ConditionOperator.GreaterThan },
];

interface AutomationsSendConditionBlockProps {
  sendCondition: SendCondition;
  buttonProps?: MenuButtonProps;
  listProps: MenuListProps;
  tags: AutomationTag[];
  canBeDeleted?: boolean;
  hasError?: boolean;
  errorText?: string;
  onUpdateCondition: (updates: Partial<SendCondition> & { attribute: SendConditionAttribute }) => void;
  onClearAllTags?: (conditionAttribute: TaggableSendConditionAttribute) => void;
  onRemoveTag?: (conditionAttribute: TaggableSendConditionAttribute, tag: string) => void;
  onDeleteCondition?: () => void;
}

export const AutomationsSendConditionBlock = ({
  sendCondition,
  buttonProps = {
    size: 'sm',
    variant: 'secondarySubtle',
    leftIconName: 'plus',
    text: 'Add',
    showRightIcon: false,
  },
  listProps,
  tags,
  canBeDeleted = false,
  hasError = false,
  errorText,
  onUpdateCondition,
  onClearAllTags,
  onRemoveTag,
  onDeleteCondition,
}: AutomationsSendConditionBlockProps): JSX.Element => {
  const title = getSendConditionLabel(sendCondition.attribute);

  const handleRemoveTag = (value: string): void => {
    if (onRemoveTag) {
      onRemoveTag(sendCondition.attribute as TaggableSendConditionAttribute, value);
    }
  };

  const handleClearAllTags = useCallback((): void => {
    if (onClearAllTags) {
      onClearAllTags(sendCondition.attribute as TaggableSendConditionAttribute);
    }
  }, [sendCondition, onClearAllTags]);

  const handleAgeOperatorChange = useCallback(
    (menuItem: MenuItemProps): void => {
      if (sendCondition.attribute === ConditionAttribute.PetAgeInYears) {
        onUpdateCondition({
          attribute: sendCondition.attribute,
          operator: menuItem.value as SendConditionPatientAgeOperator,
        });
      }
    },
    [sendCondition.attribute, onUpdateCondition],
  );

  const handleAgeValueChange = useCallback(
    (value: string): void => {
      if (sendCondition.attribute === ConditionAttribute.PetAgeInYears) {
        const ageNumber = Number(value.trim());
        if (isNaN(ageNumber)) {
          return;
        }
        const ageValue = !value.trim() ? null : ageNumber;
        onUpdateCondition({
          attribute: ConditionAttribute.PetAgeInYears,
          operand: {
            value: ageValue,
            displayUnit: sendCondition.operand.displayUnit,
          },
        });
      }
    },
    [sendCondition, onUpdateCondition],
  );

  const handleAgeDisplayUnitChange = useCallback(
    (menuItem: MenuItemProps): void => {
      if (
        sendCondition.attribute === ConditionAttribute.PetAgeInYears &&
        sendCondition.operator !== ConditionOperator.Between
      ) {
        onUpdateCondition({
          attribute: sendCondition.attribute,
          operand: { value: sendCondition.operand.value, displayUnit: menuItem.value as PatientAgeUnit },
        });
      }
    },
    [sendCondition, onUpdateCondition],
  );

  const renderInputs = useCallback(() => {
    if (sendCondition.attribute === ConditionAttribute.PetAgeInYears) {
      const { operator, operand } = sendCondition;
      return (
        <PetAgeForm
          operator={operator}
          handleAgeOperatorChange={handleAgeOperatorChange}
          operandValue={operand.value}
          displayUnit={operand.displayUnit}
          handleAgeValueChange={handleAgeValueChange}
          handleAgeDisplayUnitChange={handleAgeDisplayUnitChange}
          patientAgeOperatorOptions={patientAgeOperatorOptions}
        />
      );
    } else {
      return (
        <>
          <Menu buttonProps={buttonProps} listProps={listProps} />
          <Button
            className="Automations__TagBlock-ClearAllButton"
            size="sm"
            iconName="arrowCircle"
            variant="ghostNeutral"
            onClick={handleClearAllTags}
            isDisabled={tags.length <= 0}
          >
            Clear All
          </Button>
        </>
      );
    }
  }, [
    sendCondition,
    handleAgeOperatorChange,
    handleAgeDisplayUnitChange,
    handleAgeValueChange,
    buttonProps,
    listProps,
    handleClearAllTags,
    tags.length,
  ]);

  return (
    <Box
      position="relative"
      mb={3}
      py={2}
      pl={4}
      pr={2}
      bg="background.subtle"
      borderRadius="lg"
      boxShadow={hasError ? `0 0 0 2px var(--chakra-colors-border-danger)` : ''}
      borderWidth="1px"
      borderColor="border.default"
      className="Automations__TagBlock"
    >
      <Flex justifyContent="space-between">
        <HStack spacing={1} minH={10}>
          {title && (
            <Text
              data-testid={`send-condition-${title}`}
              flex="0 0 auto"
              mr={2}
              fontWeight="semibold"
              className="Automations__TagBlock-Title"
            >
              {title}
            </Text>
          )}
          {renderInputs()}
        </HStack>
        {canBeDeleted && (
          <Box>
            <Button
              data-testid={`send-condition-delete-${title}`}
              className="Automations__TagBlock-DeleteButton"
              iconName="trashcan"
              variant="ghostNeutral"
              onClick={onDeleteCondition}
              justifySelf="flex-end"
            />
          </Box>
        )}
      </Flex>
      {Boolean(tags.length) && (
        <Flex
          mt={2}
          pb={1}
          alignContent="space-between"
          flexWrap="wrap"
          justifyContent="flex-start"
          alignItems="flex-start"
          className="Automations__TagBlock-TagContainer"
        >
          {tags.map((tag) => {
            const isDisabled =
              tag.label.includes(TagDisabledState.Inactive) || tag.label.includes(TagDisabledState.Deleted);

            return (
              <Tag
                className="Automations__TagBlock-Tag"
                mr={1}
                mb={1}
                key={tag.value}
                label={tag.label}
                onClose={partial(handleRemoveTag, tag.value)}
                closeButtonDisplay="always"
                color={isDisabled ? 'text.placeholder' : 'text.default'}
                size="md"
                fontWeight="regular"
              />
            );
          })}
        </Flex>
      )}

      {hasError && (
        <Text mt={2} size="xs" as="p" variant="danger">
          {errorText ? errorText : 'This field contains an error'}
        </Text>
      )}
    </Box>
  );
};
