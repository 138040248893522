import { Link } from '@televet/kibble-ui';
import { Box } from '@televet/kibble-ui/build/chakra';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React, { useMemo } from 'react';
import Segment from '../components/Segment';
import { CustomListSummary } from './components/CustomSummary';
import { DeleteConfirmationModal } from './components/DeleteConfirmationModal';
import { InformationModal } from './components/InformationModal';
import { ProgressBar } from './components/ProgressBar';
import { FileInputWithDropzone } from './components/FileInputWithDropzone';
import { FileStatus, UseCustomListReturnType } from './hooks/useCustomList';

type CustomListProps = UseCustomListReturnType;

const CustomList = ({
  onSelect,
  isSelected,
  displayEmptyValidationMessage,
  campaign,
  completeProgressAnimation,
  deleteCustomList,
  downloadList,
  fileStatus,
  inputFileRef,
  isDownloading,
  recordCount,
  isDeleteConfirmationModalOpen,
  isInformationModalOpen,
  onFileChange: validateFile,
  isDeleting,
  openInformationModal,
  closeInformationModal,
  openDeleteConfirmationModal,
  closeDeleteConfirmationModal,
}: CustomListProps): JSX.Element => {
  const displayFileUploadInput = useMemo(
    () =>
      fileStatus === FileStatus.NotLoaded ||
      fileStatus === FileStatus.Loading ||
      fileStatus === FileStatus.RecipientCountValidationError ||
      fileStatus === FileStatus.ServerError ||
      fileStatus === FileStatus.BadFormatValidationError,
    [fileStatus],
  );

  return (
    <Segment
      title="Upload Custom List"
      description={
        <>
          {'Import your own list of clients by uploading a .CSV file from your computer. '}
          <Link variant="interactive" onClick={openInformationModal}>
            Formatting requirements
          </Link>
        </>
      }
      isSelected={isSelected}
      onSelect={onSelect}
    >
      <Box py="3">
        {displayFileUploadInput && <FileInputWithDropzone inputFileRef={inputFileRef} onFileChange={validateFile} />}
        {fileStatus === FileStatus.Uploading && (
          <ProgressBar recordCount={recordCount} completeProgress={completeProgressAnimation} />
        )}
        {fileStatus === FileStatus.ViewingServerFile && (
          <CustomListSummary
            campaign={campaign}
            onConfirm={openDeleteConfirmationModal}
            isDownloading={isDownloading}
            onDownload={downloadList}
          />
        )}
        {(fileStatus === FileStatus.ServerError || fileStatus === FileStatus.BadFormatValidationError) && (
          <Text as="p" color="text.danger" fontSize="sm">
            Upload failed. Check to make sure your .CSV file is formatted properly and try again.
          </Text>
        )}
        {fileStatus === FileStatus.RecipientCountValidationError && (
          <Text as="p" color="text.danger" fontSize="sm">
            Files exceeds 15k contact limit. Please reduce the number of contacts and try again.
          </Text>
        )}
        {isInformationModalOpen && <InformationModal onClose={closeInformationModal} />}
        {isDeleteConfirmationModalOpen && (
          <DeleteConfirmationModal
            onClose={closeDeleteConfirmationModal}
            onConfirm={deleteCustomList}
            isDeleting={isDeleting}
          />
        )}
        {displayEmptyValidationMessage && fileStatus === FileStatus.NotLoaded && (
          <Text as="p" color="text.danger">
            Please upload a file.
          </Text>
        )}
      </Box>
    </Segment>
  );
};

export default CustomList;
