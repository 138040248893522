// Values in TimezoneName enum need to be values supported by
// Postgres. If you need to add a new timezone, make sure to check
// that the name you are using is supported by Postgres.
export enum TimezoneName {
  US_EAST = 'America/New_York',
  US_CENTRAL = 'America/Chicago',
  US_MOUNTAIN = 'America/Denver',
  US_PACIFIC = 'America/Los_Angeles',
  US_ALASKA = 'America/Anchorage',
  US_HAWAII = 'Pacific/Honolulu',
  US_HAWAII_ALEUTIAN = 'America/Adak',
  US_ARIZONA = 'America/Phoenix', // Arizona does not observe Daylight Saving Time so effectively has its own time zone
  US_PUERTO_RICO = 'America/Puerto_Rico', // Puerto Rico does not observe Daylight Saving Time so it also has its own time zone
  US_ATLANTIC = 'Atlantic/Bermuda', // This value needs to be 'Atlantic/Bermuda' for Postgres to recognize it.
  US_ST_JOHNS = 'America/St_Johns',
}

export enum TimezoneDisplayName {
  US_EAST = 'Eastern Time',
  US_CENTRAL = 'Central Time',
  US_MOUNTAIN = 'Mountain Time',
  US_PACIFIC = 'Pacific Time',
  US_ALASKA = 'Alaska Time',
  US_HAWAII = 'Hawaii Time',
  US_HAWAII_ALEUTIAN = 'Hawaii-Aleutian Time',
  US_ARIZONA = 'Mountain Standard Time', // Arizona does not observe Daylight Saving Time so effectively has its own time zone
  US_PUERTO_RICO = 'Atlantic Standard Time', // Puerto Rico does not observe Daylight Saving Time so it also has its own time zone
  US_ATLANTIC = 'Atlantic Time',
  US_ST_JOHNS = 'Newfoundland Standard Time',
}

export enum TimezoneDisplayNameWithIdentifier {
  US_EAST = 'Eastern Time (America/New York)',
  US_CENTRAL = 'Central Time (America/Chicago)',
  US_MOUNTAIN = 'Mountain Time (America/Denver)',
  US_PACIFIC = 'Pacific Time (America/Los Angeles)',
  US_ALASKA = 'Alaska Time (America/Anchorage)',
  US_HAWAII = 'Hawaii Time (Pacific/Honolulu)',
  US_HAWAII_ALEUTIAN = 'Hawaii-Aleutian Time (America/Adak)',
  US_ARIZONA = 'Mountain Standard Time (America/Phoenix)', // Arizona does not observe Daylight Saving Time so effectively has its own time zone
  US_PUERTO_RICO = 'Atlantic Standard Time (America/Puerto Rico)', // Puerto Rico does not observe Daylight Saving Time so it also has its own time zone
  US_ATLANTIC = 'Atlantic Time (America/Atlantic)',
  US_ST_JOHNS = 'Newfoundland Standard Time (America/St Johns)',
}

export enum TimezoneAbbreviation {
  US_EAST = 'ET',
  US_CENTRAL = 'CT',
  US_MOUNTAIN = 'MT',
  US_PACIFIC = 'PT',
  US_ALASKA = 'AT',
  US_HAWAII = 'HST',
  US_HAWAII_ALEUTIAN = 'HT',
  US_ARIZONA = 'MST',
  US_PUERTO_RICO = 'AST',
  US_ATLANTIC = 'AT',
  US_ST_JOHNS = 'NT',
}
