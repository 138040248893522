import { StackProps, VStack } from '@televet/kibble-ui/build/chakra';
import { Heading } from '@televet/kibble-ui/build/components/Heading';
import { Text } from '@televet/kibble-ui/build/components/Text';
import React from 'react';

interface EditButtonModalSectionHeaderProps extends StackProps {
  title: string;
  description?: string;
}

const EditButtonModalSectionHeader = ({
  title,
  description,
  ...rest
}: EditButtonModalSectionHeaderProps): JSX.Element => {
  return (
    <VStack alignItems="flex-start" {...rest}>
      <Heading mb={!!description ? -1 : 0} size="md">
        {title}
      </Heading>
      {!!description && (
        <Text variant="subtle" mt={0}>
          {description}
        </Text>
      )}
    </VStack>
  );
};

export default EditButtonModalSectionHeader;
