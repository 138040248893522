export enum WildcardValues {
  Time = '{{time}}',
  PetName = '{{petName}}',
  ClientName = '{{clientName}}',
  ClientFirstName = '{{clientFirstName}}',
  ClientLastName = '{{clientLastName}}',
  ClientPimsId = '{{clientPimsId}}',
  ClinicName = '{{clinicName}}',
  EmployeeName = '{{employeeName}}',
  FillInTheBlank = '{{fillInTheBlank}}',
  Date = '{{date}}',
  MyName = '{{myName}}',
  ClientPhone = '{{clientPhone}}',
  ClientEmail = '{{clientEmail}}',
  PetSpecies = '{{petSpecies}}',
  PetPimsId = '{{petPimsId}}',
  AppointmentType = '{{appointmentType}}',
  PetPortalLinksForPets = '{{petPortalLinksForPets}}',
  PetPortalLinksForClients = '{{petPortalLinksForClients}}',
  HillsToHomeRegistrationUrl = '{{hillsToHomeRegistrationUrl}}',
}

export enum WildcardDisplayNames {
  Time = 'Time',
  PetName = 'Pet Name',
  ClientName = 'Client Name',
  ClientFirstName = 'Client First Name',
  ClientLastName = 'Client Last Name',
  ClientPimsId = 'Client Id',
  ClinicName = 'Clinic Name',
  EmployeeName = 'Employee Name',
  FillInTheBlank = 'Fill In the Blank',
  Date = 'Date',
  MyName = 'My Name',
  ClientPhone = 'Client Phone',
  ClientEmail = 'Client Email',
  PetSpecies = 'Pet Species',
  PetPimsId = 'Pet Id',
  AppointmentType = 'Appointment Type',
  PetPortalLinksForPets = 'Pet Portal Links for Pets',
  PetPortalLinksForClients = 'Pet Portal Links for Clients',
  HillsToHomeRegistrationUrl = "Hill's to Home Registration Url",
}

export const allWildcards = [
  { label: WildcardDisplayNames.Time, value: WildcardValues.Time },
  { label: WildcardDisplayNames.PetName, value: WildcardValues.PetName },
  { label: WildcardDisplayNames.ClinicName, value: WildcardValues.ClinicName },
  { label: WildcardDisplayNames.ClientName, value: WildcardValues.ClientName },
  { label: WildcardDisplayNames.ClientFirstName, value: WildcardValues.ClientFirstName },
  { label: WildcardDisplayNames.ClientLastName, value: WildcardValues.ClientLastName },
  { label: WildcardDisplayNames.EmployeeName, value: WildcardValues.EmployeeName },
  { label: WildcardDisplayNames.FillInTheBlank, value: WildcardValues.FillInTheBlank },
  { label: WildcardDisplayNames.Date, value: WildcardValues.Date },
  { label: WildcardDisplayNames.MyName, value: WildcardValues.MyName },
  { label: WildcardDisplayNames.ClientPhone, value: WildcardValues.ClientPhone },
  { label: WildcardDisplayNames.ClientEmail, value: WildcardValues.ClientEmail },
  { label: WildcardDisplayNames.PetSpecies, value: WildcardValues.PetSpecies },
  { label: WildcardDisplayNames.AppointmentType, value: WildcardValues.AppointmentType },
  { label: WildcardDisplayNames.PetPortalLinksForPets, value: WildcardValues.PetPortalLinksForPets },
  { label: WildcardDisplayNames.ClientPimsId, value: WildcardValues.ClientPimsId },
  { label: WildcardDisplayNames.PetPimsId, value: WildcardValues.PetPimsId },
];
