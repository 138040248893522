import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AutomatedTriggerType,
  WorkflowEvent,
  AutomationRunActionPromptType,
  WorkflowEventTriggerType,
  ConditionAttribute,
  ConditionOperator,
} from 'shared/types/graphql';
import { StateSliceName } from 'state/StateSliceName';
import { AutomationDraft, TimeIncrement, SendTiming } from '../types/AutomationDraft';
import { AutomationPrompt } from '../types/AutomationPrompt';
import { SendConditions, SendConditionAttribute } from '../types/AutomationSendConditions';
import {
  initialErrorsState,
  Errors,
  PromptErrorDetail,
  SendConditionErrorDetail,
  ButtonErrorDetail,
  ValidationActionWord,
} from '../types/AutomationValidation';
import { initialAutomationDraft } from './utils/draft';
import { PatientAgeUnit, SendConditionPatientAgeLessGreaterThan } from '../types/SendConditionPatientAge';
import { IConditionalNextStepConfig, IMessageButtonOption, WorkflowEventActionConfig } from '@televet/shared-types';

interface AutomationsState {
  automationDraft: AutomationDraft;
  shouldBlockNavigation: boolean;
  errors: Errors;
  isErrorAlertVisible: boolean;
  validationActionWord: ValidationActionWord;
  isGlobalEditor?: boolean;
}

const initialState: AutomationsState = {
  automationDraft: initialAutomationDraft,
  shouldBlockNavigation: true,
  errors: initialErrorsState,
  isErrorAlertVisible: false,
  validationActionWord: ValidationActionWord.Saving,
};

const automationsSlice = createSlice({
  name: StateSliceName.Automations,
  initialState,
  reducers: {
    resetAutomationsState: (state, action: PayloadAction<{ preserveAutomationDraft?: boolean } | undefined>) => {
      if (action?.payload?.preserveAutomationDraft) {
        Object.assign(state, initialState, { automationDraft: state.automationDraft });
      } else {
        Object.assign(state, initialState);
      }
    },
    updateAutomationDraft: (state, action: PayloadAction<Partial<AutomationDraft>>) => {
      Object.assign(state.automationDraft, action.payload);
    },
    updateAutomationType: (state, action: PayloadAction<WorkflowEvent>) => {
      const currentSendTiming = state.automationDraft.sendTiming;
      const newAutomationType: WorkflowEvent = action.payload;

      const newSendTiming: SendTiming = {
        triggerType: AutomatedTriggerType.Before,
        timeValue: currentSendTiming?.timeValue || 1,
        timeIncrement: currentSendTiming?.timeIncrement || TimeIncrement.Hours,
      };

      if (newAutomationType === WorkflowEvent.AppointmentFollowup) {
        newSendTiming.triggerType = AutomatedTriggerType.After;
      }

      if (newAutomationType === WorkflowEvent.LapsedPetParent) {
        newSendTiming.triggerType = null;
        newSendTiming.timeValue = 24;
        newSendTiming.timeIncrement = TimeIncrement.Months;
      }

      state.automationDraft.triggerType = WorkflowEventTriggerType.Automated;
      state.automationDraft.sendTiming = newSendTiming;
      state.automationDraft.automationType = action.payload;
    },
    updateAutomationSendTiming: (state, action: PayloadAction<Partial<SendTiming>>) => {
      Object.assign(state.automationDraft.sendTiming, action.payload);
    },
    updateAutomationSendConditions: (state, action: PayloadAction<Partial<SendConditions>>) => {
      Object.assign(state.automationDraft.sendConditions, action.payload);
    },
    updateAutomationPromptOrder: (state, action: PayloadAction<{ currentIndex: number; newIndex: number }>) => {
      const { currentIndex, newIndex } = action.payload;
      const promptToBeMoved = state.automationDraft.prompts[currentIndex];
      const filteredPromptsList = state.automationDraft.prompts.filter((prompt, index) => index !== currentIndex);

      if (promptToBeMoved) {
        filteredPromptsList.splice(newIndex, 0, promptToBeMoved);
        filteredPromptsList.forEach((prompt, index) => {
          const newOrder = index + 1;
          prompt.order = newOrder;

          // Update `fallbackNextActionNumber` in config objects to point to the new next action
          if (typeof prompt.config.conditionalNextStepConfig?.fallbackNextActionNumber === 'number') {
            prompt.config.conditionalNextStepConfig.fallbackNextActionNumber = newOrder + 1;
          }
          if (
            prompt.config.buttons?.some(
              (button) => typeof button.conditionalNextStepConfig?.fallbackNextActionNumber === 'number',
            )
          ) {
            prompt.config.buttons.forEach((button) => {
              if (typeof button.conditionalNextStepConfig?.fallbackNextActionNumber === 'number') {
                button.conditionalNextStepConfig.fallbackNextActionNumber = newOrder + 1;
              }
            });
          }
        });
        state.automationDraft.prompts = filteredPromptsList;
      }
    },
    addAutomationPrompt: (
      state,
      action: PayloadAction<{ promptType: AutomationRunActionPromptType; atIndex: number }>,
    ) => {
      const { promptType, atIndex } = action.payload;
      const newPromptsList = [...state.automationDraft.prompts];
      const newPromptData = {
        id: '',
        order: atIndex + 1,
        promptType,
        config:
          promptType === AutomationRunActionPromptType.Buttons
            ? {
                callToActionBody: 'Please select a button below:',
              }
            : {},
        channelStatusChangeId: null,
        triggerDelayInMilliseconds: 500,
        triggerActionNumber:
          promptType === AutomationRunActionPromptType.Statement ? null : state.automationDraft.prompts.length + 2,
      };

      newPromptsList.splice(atIndex, 0, newPromptData);

      state.automationDraft.prompts = newPromptsList;
    },
    removeAutomationPrompt: (state, action: PayloadAction<{ promptIndex: number }>) => {
      const newPromptsList = [...state.automationDraft.prompts];
      newPromptsList.splice(action.payload.promptIndex, 1);

      newPromptsList.forEach((prompt, index) => {
        prompt.order = index + 1;
      });

      state.automationDraft.prompts = newPromptsList;
    },
    updateAutomationPrompt: (
      state,
      action: PayloadAction<Partial<AutomationPrompt> & { promptIndex: number; prompt: Partial<AutomationPrompt> }>,
    ) => {
      const prompt = state.automationDraft.prompts[action.payload.promptIndex];
      if (prompt) {
        Object.assign(prompt, action.payload.prompt);
      }
    },
    updateAutomationPromptConfig: (
      state,
      action: PayloadAction<{ promptIndex: number; config: Partial<WorkflowEventActionConfig> }>,
    ) => {
      const prompt = state.automationDraft.prompts[action.payload.promptIndex];
      if (prompt) {
        Object.assign(prompt.config, action.payload.config);
      }
    },
    // Used to update parts of a button config
    updateAutomationPromptButton: (
      state,
      action: PayloadAction<{ promptIndex: number; buttonIndex: number; button: Partial<IMessageButtonOption> }>,
    ) => {
      const prompt = state.automationDraft.prompts[action.payload.promptIndex];
      if (prompt?.config?.buttons) {
        Object.assign(prompt.config.buttons[action.payload.buttonIndex], action.payload.button);
      }
    },
    // Used to fully overwrite a button config, e.g. to reset it after cancelling an edit
    setAutomationPromptButton: (
      state,
      action: PayloadAction<{ promptIndex: number; buttonIndex: number; button: IMessageButtonOption }>,
    ) => {
      const prompt = state.automationDraft.prompts[action.payload.promptIndex];
      if (prompt?.config?.buttons) {
        prompt.config.buttons[action.payload.buttonIndex] = action.payload.button;
      }
    },
    updateAutomationPromptNextStep: (
      state,
      action: PayloadAction<{
        promptIndex: number;
        triggerActionNumber: number | null;
        triggerWorkflowId: string | null;
      }>,
    ) => {
      const { promptIndex, triggerActionNumber, triggerWorkflowId } = action.payload;
      const prompt = state.automationDraft.prompts[promptIndex];
      if (prompt) {
        Object.assign(prompt.config, { nextWorkflowEventTriggerSettingId: triggerWorkflowId });
        Object.assign(prompt, {
          triggerActionNumber,
        });
      }
    },
    updateAutomationPromptFollowupStep: (
      state,
      action: PayloadAction<{ promptIndex: number } & Partial<IConditionalNextStepConfig>>,
    ) => {
      const { promptIndex, fallbackNextActionNumber, fallbackNextWorkflowEventSettingId } = action.payload;
      const prompt = state.automationDraft.prompts[promptIndex];
      if (prompt) {
        Object.assign(prompt.config, {
          conditionalNextStepConfig: { fallbackNextActionNumber, fallbackNextWorkflowEventSettingId },
        });
      }
    },
    updateShouldBlockNavigation: (state, action: PayloadAction<boolean>) => {
      state.shouldBlockNavigation = action.payload;
    },
    updateAutomationErrors: (state, action: PayloadAction<Partial<Errors>>) => {
      Object.assign(state.errors, action.payload);
    },
    updateAutomationSendConditionErrors: (
      state,
      action: PayloadAction<{ conditionAttribute: SendConditionAttribute; errors: SendConditionErrorDetail[] }>,
    ) => {
      Object.assign(state.errors.sendConditions, { [action.payload.conditionAttribute]: action.payload.errors });
    },
    updateAutomationPromptErrors: (
      state,
      action: PayloadAction<{ promptIndex: number; prompt: Partial<PromptErrorDetail> }>,
    ) => {
      const prompt = state.errors.prompts.find(({ promptIndex }) => promptIndex === action.payload.promptIndex);
      if (prompt) {
        Object.assign(prompt, action.payload.prompt);
      }
    },
    updateAutomationPromptButtonErrors: (
      state,
      action: PayloadAction<{ promptIndex: number; buttonIndex: number; button: Partial<ButtonErrorDetail> }>,
    ) => {
      const prompt = state.errors.prompts.find(({ promptIndex }) => promptIndex === action.payload.promptIndex);
      if (prompt?.buttons) {
        Object.assign(prompt.buttons[action.payload.buttonIndex], action.payload.button);
      }
    },
    addSendCondition: (state, action: PayloadAction<SendConditionAttribute>) => {
      if (action.payload === ConditionAttribute.PetAgeInYears) {
        state.automationDraft.sendConditions[ConditionAttribute.PetAgeInYears] = {
          attribute: ConditionAttribute.PetAgeInYears,
          operator: ConditionOperator.LessThan,
          operand: { value: 3, displayUnit: PatientAgeUnit.Years },
        } as SendConditionPatientAgeLessGreaterThan;
      } else {
        state.automationDraft.sendConditions[action.payload] = JSON.parse(
          JSON.stringify({
            attribute: action.payload,
            operator: ConditionOperator.In,
            operand: { value: [] },
          }),
        );
      }
    },
    removeVisibleSendCondition: (state, action: PayloadAction<SendConditionAttribute>) => {
      delete state.automationDraft.sendConditions[action.payload];
    },
    updateValidationActionWord: (state, action: PayloadAction<ValidationActionWord>) => {
      state.validationActionWord = action.payload;
    },
    updateErrorAlertIsVisible: (state, action: PayloadAction<boolean>) => {
      state.isErrorAlertVisible = action.payload;
    },
    setIsGlobalEditor: (state, action: PayloadAction<boolean>) => {
      state.isGlobalEditor = action.payload;
    },
  },
});

export const {
  resetAutomationsState,
  updateAutomationDraft,
  updateAutomationType,
  updateAutomationSendTiming,
  updateAutomationSendConditions,
  addAutomationPrompt,
  removeAutomationPrompt,
  updateAutomationPrompt,
  updateAutomationPromptNextStep,
  updateAutomationPromptFollowupStep,
  updateAutomationPromptConfig,
  updateAutomationPromptButton,
  setAutomationPromptButton,
  updateShouldBlockNavigation,
  updateAutomationErrors,
  updateAutomationPromptButtonErrors,
  addSendCondition,
  removeVisibleSendCondition,
  updateAutomationPromptErrors,
  updateAutomationSendConditionErrors,
  updateValidationActionWord,
  updateAutomationPromptOrder,
  updateErrorAlertIsVisible,
  setIsGlobalEditor,
} = automationsSlice.actions;

export default automationsSlice.reducer;
