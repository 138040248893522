import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TimezoneName } from 'shared/enums/TimezoneNames';
import {
  ClinicOfficeHourSelectionFragment,
  ClinicOfficeHoursType,
  ClinicUpdateInput,
  EmergencyClinicsFragment,
  useGetClinicSettingsConversationsSmsQuery,
  useGetClinicSettingsGeneralQuery,
  useUpdateClinicSettingsClinicMutation,
  useUpdateClinicSettingsOutOfOfficeInformationMutation,
  useUpdateEmergencyClinicDisplayOrderMutation,
} from 'shared/types/graphql';
import { getTimezoneDisplayNameWithIdentifier } from 'shared/utils/timezoneDisplayNames';
import styled from 'styled-components/macro';
import { parseISO, addMilliseconds } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { Mixpanel } from 'shared/utils/mixpanel';
import useClinicUser from 'shared/hooks/useClinicUser';
import { STATUSES, useCustomToast } from 'shared/hooks/useCustomToast';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import OfficeHoursPicker, { CanceledButtonClicked } from '../GeneralInfoSettings/OfficeHoursPicker';
import Switch from 'shared/components/Switch';
import Draggable from 'shared/components/Draggable/Draggable';
import Dropdown, { PopoverPlacement, IOption } from 'shared/components/Dropdown';
import { formatPhoneNumber } from 'shared/utils';
import MoreOptionsButton from 'shared/components/MoreOptionsButton';
import partial from 'lodash-es/partial';
import EditEmergencyContactModal from '../GeneralInfoSettings/EditEmergencyContactModal';
import DeleteEmergencyContactModal from '../GeneralInfoSettings/DeleteEmergencyContactModal';
import AutoResponseTextArea, { SaveOOOResponse } from '../TextMessagingSettings/AutoResponseTextArea';
import {
  Heading,
  Icon,
  Text,
  Box,
  Divider,
  Flex,
  HStack,
  CSelect,
  CTextarea,
  VStack,
  useDisclosure,
} from '@televet/kibble-ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const timeZoneValues = Object.values(TimezoneName).filter((value: any) => typeof value === 'string');

enum ClinicMenuOptions {
  Edit = 'edit',
  Delete = 'delete',
}

export enum ClinicHoursUpdateField {
  OOO_Headline = 'outOfOfficeHeadline',
  Timezone = 'clinicTimezone',
  OfficeHoursState = 'officeHoursState',
  OOOResponse = 'OOOResponse',
}

interface officeHoursSetting {
  id: string;
  outOfOfficeHeadline: string;
  outOfOfficeEnabled: boolean;
  outOfOfficeShowScheduleEnabled: boolean;
  emergencyContactsEnabled: boolean;
  emergencyClinics: EmergencyClinicsFragment[];
}

const emergencyClinicMenuDropdownOptions = [
  {
    value: ClinicMenuOptions.Edit,
    text: 'Edit clinic',
  },
  {
    value: ClinicMenuOptions.Delete,
    text: 'Delete clinic',
  },
];

const ClinicHoursSettings = forwardRef(
  (
    {
      hasPermission,
      setHasUnsavedChange,
    }: {
      hasPermission?: boolean;
      setHasUnsavedChange: React.Dispatch<React.SetStateAction<boolean>>;
    },
    ref,
  ): JSX.Element => {
    // ref
    const clinicHourSaveResRef = useRef<SaveOOOResponse>(null);
    const clinicHourCancelButtonRef = useRef<CanceledButtonClicked>(null);

    // custom hooks
    const { currentClinicId } = useClinicUser();
    const { toastMessagePop } = useCustomToast();

    // page state
    const [clinicTimezone, setClinicTimezone] = useState<string>('');
    const [officeHoursState, setOfficeHoursState] = useState<ClinicOfficeHourSelectionFragment[]>([]);
    const [newOfficeHours, setNewOfficeHours] = useState<ClinicOfficeHourSelectionFragment[]>([]);
    const [officeHourSettingsState, setOfficeHourSettingState] = useState<officeHoursSetting>({
      id: '',
      outOfOfficeHeadline: '',
      outOfOfficeEnabled: false,
      outOfOfficeShowScheduleEnabled: false,
      emergencyContactsEnabled: false,
      emergencyClinics: [],
    });
    const [clinicForDelete, setClinicForDelete] = useState<EmergencyClinicsFragment | undefined>();
    const [clinicForEdit, setClinicForEdit] = useState<EmergencyClinicsFragment | undefined>();
    const [clinicHoursChanges, setClinicHoursChanges] = useState<ClinicHoursUpdateField[]>([]);

    const [updateClinicSettings] = useUpdateClinicSettingsClinicMutation();
    const { data: currentClinic, refetch: refetchGeneralSettingsData } = useGetClinicSettingsGeneralQuery({
      variables: { id: currentClinicId || '' },
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
      skip: !currentClinicId,
    });
    const { data: OOOResponseQuery, refetch: refetchClinicSmsSettings } = useGetClinicSettingsConversationsSmsQuery({
      variables: {
        id: currentClinicId,
        clinicId: currentClinicId || '',
      },
      skip: !currentClinicId,
      fetchPolicy: GraphQLFetchPolicies.CacheAndNetwork,
    });
    const [updateClinicOutOfOfficeSettings] = useUpdateClinicSettingsOutOfOfficeInformationMutation();
    const [updateEmergencyClinicDisplayOrderMutation, { loading: displayOrderIsLoading }] =
      useUpdateEmergencyClinicDisplayOrderMutation();

    const clinicData = currentClinic?.findUniqueClinic;

    // modal stuff
    const { isOpen: isDeleteConfirmOpen, onOpen: openDeleteConfirm, onClose: closeDeleteConfirm } = useDisclosure();
    const { isOpen: isEditModalOpen, onOpen: openEditModal, onClose: closeEditModal } = useDisclosure();

    // Callback section
    const setInitialData = useCallback(() => {
      if (clinicHoursChanges.length === 0) {
        const clinicOutOfOfficeSettings = clinicData?.clinicSetting;
        setClinicTimezone(clinicData?.timezoneName || '');

        if (clinicOutOfOfficeSettings) {
          const activeEmergencyClinics = clinicOutOfOfficeSettings.emergencyClinics
            .filter((c) => !c.isDeleted)
            .sort(
              (a: EmergencyClinicsFragment, b: EmergencyClinicsFragment): number => a.displayOrder - b.displayOrder,
            );

          setOfficeHourSettingState({
            id: clinicOutOfOfficeSettings.id,
            outOfOfficeHeadline: clinicOutOfOfficeSettings.outOfOfficeHeadline,
            outOfOfficeEnabled: clinicOutOfOfficeSettings.outOfOfficeEnabled,
            outOfOfficeShowScheduleEnabled: clinicOutOfOfficeSettings.outOfOfficeShowScheduleEnabled,
            emergencyContactsEnabled: clinicOutOfOfficeSettings.emergencyContactsEnabled,
            emergencyClinics: activeEmergencyClinics,
          });
        }

        if (clinicData) {
          const officeHours = clinicData.clinicOfficeHours.map((officeHour: ClinicOfficeHourSelectionFragment) => {
            return {
              ...officeHour,
              openAt: parseISO(officeHour.openAt),
              closeAt: parseISO(officeHour.closeAt),
            };
          });
          if (officeHours.length) {
            const officeHoursCopy = [...officeHours];

            officeHoursCopy.push(officeHoursCopy[0]);
            officeHoursCopy.shift();

            setOfficeHoursState(officeHoursCopy);
          }
        }
        setClinicHoursChanges([]);
        setHasUnsavedChange(false);
      }
    }, [clinicData, clinicHoursChanges.length, setHasUnsavedChange]);

    // useEffect section
    useEffect(() => {
      setInitialData();
    }, [setInitialData]);

    // custom handler function
    const onUpdate = useCallback(
      async (data: ClinicUpdateInput) => {
        if (currentClinicId) {
          try {
            await updateClinicSettings({
              variables: { data, id: currentClinicId },
            });

            toastMessagePop();
            await refetchGeneralSettingsData();
          } catch (e) {
            console.error(e);
            toastMessagePop('Your clinic was unable to be updated. Please try again.', STATUSES.error);
          }
        }
      },
      [currentClinicId, updateClinicSettings, toastMessagePop, refetchGeneralSettingsData],
    );

    const handleClinicTimezoneChange = useCallback(
      (value: string): void => {
        const timezoneName = value;
        setClinicTimezone(timezoneName);
        if (timezoneName !== clinicData?.timezoneName) {
          setClinicHoursChanges((prevVal) => {
            const newVal = prevVal || [];
            if (newVal.includes(ClinicHoursUpdateField.Timezone) === false) {
              return [...newVal, ClinicHoursUpdateField.Timezone];
            }
            return newVal;
          });
          setHasUnsavedChange(true);
          Mixpanel.track('General Settings Timezone selected');
        } else if (timezoneName === clinicData?.timezoneName) {
          const newState = clinicHoursChanges?.filter(
            (clinicHourUpdateField) => clinicHourUpdateField !== ClinicHoursUpdateField.Timezone,
          );
          setClinicHoursChanges(() => {
            return newState || [];
          });
          if (!!newState) {
            setHasUnsavedChange(false);
          }
        }
      },
      [clinicData?.timezoneName, clinicHoursChanges, setHasUnsavedChange],
    );

    const onOOOSettingsToggle = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const newState = {
        ...officeHourSettingsState,
        [e.target.name]: checked,
      };
      setOfficeHourSettingState(newState);
      updateClinicOutOfOfficeSettings({
        variables: {
          id: officeHourSettingsState.id,
          data: {
            [e.target.name]: checked,
          },
        },
      });

      toastMessagePop();
    };

    const onOOOHeadlineChange = (value: string): void => {
      const newState = {
        ...officeHourSettingsState,
        [ClinicHoursUpdateField.OOO_Headline]: value,
      };
      setOfficeHourSettingState(newState);
      setHasUnsavedChange(true);

      if (value.trim() !== currentClinic?.findUniqueClinic?.clinicSetting?.outOfOfficeHeadline) {
        setClinicHoursChanges((prevVal) => {
          const newVal = prevVal || [];
          if (newVal.includes(ClinicHoursUpdateField.OOO_Headline) === false) {
            return [...newVal, ClinicHoursUpdateField.OOO_Headline];
          }
          return newVal;
        });
      } else if (value.trim() === currentClinic?.findUniqueClinic?.clinicSetting?.outOfOfficeHeadline) {
        const newState = clinicHoursChanges?.filter(
          (clinicHourUpdateField) => clinicHourUpdateField !== ClinicHoursUpdateField.OOO_Headline,
        );
        setClinicHoursChanges(() => {
          return newState || [];
        });
        if (newState.length === 0) {
          setHasUnsavedChange(false);
        }
      }
    };

    const handleClinicMenuOptionClick = (
      clinic: EmergencyClinicsFragment,
      e: React.MouseEvent<Element, MouseEvent>,
      option: IOption,
    ): void => {
      if (option.value === ClinicMenuOptions.Edit) {
        editContact(clinic);
      }
      if (option.value === ClinicMenuOptions.Delete) {
        deleteClinic(clinic);
      }
    };

    const handleEmergencyClinicHover = async (dragIndex: number, hoverIndex: number): Promise<void> => {
      if (dragIndex === hoverIndex || displayOrderIsLoading) return;

      const emergencyClinicsCopy = officeHourSettingsState.emergencyClinics
        ? [...officeHourSettingsState.emergencyClinics]
        : [];

      const currentItem = emergencyClinicsCopy[dragIndex];

      emergencyClinicsCopy.splice(dragIndex, 1);
      emergencyClinicsCopy.splice(hoverIndex, 0, currentItem);

      // Re-index the order
      const orderedItems = emergencyClinicsCopy.map((item, index) => {
        return {
          ...item,
          order: index,
        };
      });

      setOfficeHourSettingState({
        ...officeHourSettingsState,
        emergencyClinics: orderedItems,
      });
    };

    const editContact = (clinic?: EmergencyClinicsFragment): void => {
      setClinicForEdit(clinic);
      openEditModal();
      Mixpanel.track('General Settings Edit Emergency Clinic');
    };

    const deleteClinic = (clinic: EmergencyClinicsFragment): void => {
      setClinicForDelete(clinic);
      openDeleteConfirm();
      Mixpanel.track('General Settings Delete Emergency Clinic');
    };

    const handleEmergencyClinicDrop = async (dragIndex: number): Promise<void> => {
      if (displayOrderIsLoading) return;

      const currentItem = officeHourSettingsState.emergencyClinics[dragIndex];
      const newDisplayOrder = dragIndex + 1;
      if (newDisplayOrder === currentItem.displayOrder) return;

      try {
        await updateEmergencyClinicDisplayOrderMutation({
          variables: {
            where: { id: currentItem.id },
            data: newDisplayOrder,
          },
        });
      } catch (e) {
        console.error(e);
        toastMessagePop('Emergency clinic display order was unable to be updated. Please try again.', STATUSES.error);
        refetchGeneralSettingsData();
      }
    };

    const onCloseDelete = async (): Promise<void> => {
      await refetchGeneralSettingsData();

      closeDeleteConfirm();
    };

    const onCloseEdit = async (): Promise<void> => {
      await refetchGeneralSettingsData();

      closeEditModal();
    };

    const onOfficeHourPickerChange = (
      hasOfficeHourChanged: boolean,
      newOfficeHours?: ClinicOfficeHourSelectionFragment[],
    ): void => {
      if (hasOfficeHourChanged && !!newOfficeHours) {
        setHasUnsavedChange(true);
        setClinicHoursChanges((prevVal) => {
          const newVal = prevVal || [];
          if (newVal.includes(ClinicHoursUpdateField.OfficeHoursState) === false) {
            return [...newVal, ClinicHoursUpdateField.OfficeHoursState];
          }
          return newVal;
        });
        setNewOfficeHours(newOfficeHours);
      } else {
        const newState = clinicHoursChanges?.filter(
          (clinicHourUpdateField) => clinicHourUpdateField !== ClinicHoursUpdateField.OfficeHoursState,
        );
        setClinicHoursChanges(() => {
          return newState || [];
        });
        if (newState.length == 0) {
          setHasUnsavedChange(false);
        }
      }
    };

    const onOOOResponseChange = useCallback(
      (OOOResponseChanged: boolean): void => {
        if (OOOResponseChanged) {
          setHasUnsavedChange(true);
          setClinicHoursChanges((prevVal) => {
            const newVal = prevVal || [];
            if (newVal.includes(ClinicHoursUpdateField.OOOResponse) === false) {
              return [...newVal, ClinicHoursUpdateField.OOOResponse];
            }
            return newVal;
          });
        } else {
          const newState = clinicHoursChanges?.filter(
            (clinicHourUpdateField) => clinicHourUpdateField !== ClinicHoursUpdateField.OOOResponse,
          );
          setClinicHoursChanges(() => {
            return newState || [];
          });
          if (newState.length === 0) {
            setHasUnsavedChange(false);
          }
        }
      },
      [clinicHoursChanges, setHasUnsavedChange],
    );

    const onSaveClinicHoursChange = async (): Promise<void> => {
      if (clinicHoursChanges?.includes(ClinicHoursUpdateField.OOO_Headline)) {
        await updateClinicOutOfOfficeSettings({
          variables: {
            id: officeHourSettingsState.id,
            data: {
              [ClinicHoursUpdateField.OOO_Headline]:
                officeHourSettingsState.outOfOfficeHeadline || 'Sorry! You’ve reached us after hours.',
            },
          },
        });
        toastMessagePop();
      }

      if (clinicHoursChanges?.includes(ClinicHoursUpdateField.Timezone)) {
        const timezoneName = clinicTimezone;

        const updatedOfficeHours = officeHoursState.map((officeHour: ClinicOfficeHourSelectionFragment) => {
          const { id, openAt, closeAt } = officeHour;
          // Change moment in time by difference between old and new time zone
          const openAtDifference =
            getTimezoneOffset(clinicData?.timezoneName || TimezoneName.US_CENTRAL, openAt) -
            getTimezoneOffset(clinicTimezone, openAt);
          const closeAtDifference =
            getTimezoneOffset(clinicData?.timezoneName || TimezoneName.US_CENTRAL, closeAt) -
            getTimezoneOffset(clinicTimezone, closeAt);

          return {
            where: { id },
            data: {
              openAt: addMilliseconds(openAt, openAtDifference),
              closeAt: addMilliseconds(closeAt, closeAtDifference),
            },
          };
        });
        onUpdate({ timezoneName, clinicOfficeHours: { update: updatedOfficeHours } });
      }

      if (clinicHoursChanges?.includes(ClinicHoursUpdateField.OfficeHoursState)) {
        const updatedOfficeHours = newOfficeHours.map((officeHour: ClinicOfficeHourSelectionFragment) => {
          const { id, openAt, closeAt, isEnabled, isOpenTwentyFourHours } = officeHour;

          return {
            where: { id },
            data: {
              openAt,
              closeAt,
              isEnabled,
              isOpenTwentyFourHours,
            },
          };
        });
        onUpdate({ clinicOfficeHours: { update: updatedOfficeHours } });
      }

      if (clinicHoursChanges?.includes(ClinicHoursUpdateField.OOOResponse)) {
        clinicHourSaveResRef.current?.onSaveOOOResponse();
        toastMessagePop();
      }
      setClinicHoursChanges([]);
    };

    const onChangesSaveCancelled = (): void => {
      setClinicTimezone(clinicData?.timezoneName || '');
      setInitialData();
      refetchClinicSmsSettings();
      clinicHourSaveResRef.current?.onSetInitialData();
      clinicHourCancelButtonRef.current?.onResetOfficeHours();
    };

    useImperativeHandle(ref, () => ({
      onLeavingCurrentPage(): void {
        setClinicHoursChanges([]);
      },
      onClickedSaveButton(): void {
        onSaveClinicHoursChange();
      },
      onClickedCancelButton(): void {
        const officeHourSettingsStateCopy = officeHourSettingsState;
        officeHourSettingsStateCopy.outOfOfficeHeadline = clinicData?.clinicSetting?.outOfOfficeHeadline || '';
        setOfficeHourSettingState(officeHourSettingsStateCopy);
        onChangesSaveCancelled();
      },
    }));
    return (
      <>
        <EditEmergencyContactModal isOpen={isEditModalOpen} onClose={onCloseEdit} clinic={clinicForEdit} />
        {clinicForDelete && (
          <DeleteEmergencyContactModal isOpen={isDeleteConfirmOpen} onClose={onCloseDelete} clinic={clinicForDelete} />
        )}
        <VStack spacing={0} margin="20px 0px" alignItems={'flex-start'}>
          <Text size="md" fontWeight="bold" mb={2}>
            Time Zone
          </Text>
          {hasPermission ? (
            <CSelect
              width="fit-content"
              size="md"
              variant="unstyled"
              fontSize="14px"
              color="#060a3a"
              letterSpacing="0.13px"
              _disabled={{ color: '#060a3a', opacity: 1 }}
              onChange={(e): void => handleClinicTimezoneChange(e.target.value)}
              value={clinicTimezone}
            >
              <option hidden>Select Time Zone</option>
              {timeZoneValues.map(
                (timeZone: string | TimezoneName): JSX.Element => (
                  <option key={`zone-${timeZone}`} value={timeZone}>
                    {getTimezoneDisplayNameWithIdentifier(timeZone)}
                  </option>
                ),
              )}
            </CSelect>
          ) : (
            <Text size="sm">{getTimezoneDisplayNameWithIdentifier(clinicTimezone)}</Text>
          )}
        </VStack>
        <Divider mt={5} border="solid 1px #e8e9e9" />

        <Box my={5}>
          <Text size="md" fontWeight="bold" mb={2}>
            Clinic Hours
          </Text>
        </Box>
        <OfficeHoursPicker
          ref={clinicHourCancelButtonRef}
          hasPermission={hasPermission}
          clinicOfficeHoursType={ClinicOfficeHoursType.BusinessHours}
          clinicHoursData={officeHoursState}
          clinicTimezone={clinicTimezone}
          toastMessagePop={toastMessagePop}
          setClinicHoursChanges={(
            hasOfficeHourChanged: boolean,
            newOfficeHours?: ClinicOfficeHourSelectionFragment[],
          ): void => onOfficeHourPickerChange(hasOfficeHourChanged, newOfficeHours)}
        />
        <Divider mt={5} />

        <Heading size="md" my="20px">
          Out-of-Office Settings
        </Heading>
        <Text size="md" fontWeight="bold" mb={2}>
          Pet Portal
        </Text>
        <Box zIndex="0" position="relative" my={5}>
          {hasPermission && (
            <Switch
              hasPermission={hasPermission}
              name="outOfOfficeEnabled"
              label={hasPermission ? 'Enable Out Of Office Message' : ''}
              checked={officeHourSettingsState.outOfOfficeEnabled || false}
              onChange={(e, checked): void => onOOOSettingsToggle(e, checked)}
            />
          )}
          <>
            <Flex
              display={officeHourSettingsState.outOfOfficeEnabled ? 'flex' : 'none'}
              alignItems="flex-start"
              direction="column"
            >
              <Text size="sm" fontWeight="bold" mb={2}>
                Out-Of-Office Headline
              </Text>
              <CTextarea
                width={477}
                rows={5}
                cols={40}
                maxLength={200}
                border="solid 1px #d2d2d2"
                focusBorderColor="#d2d2d2"
                name="outOfOfficeHeadline"
                fontSize="14px"
                letterSpacing="0.13px"
                color="#060a3a"
                mb="15px"
                placeholder="Sorry! You’ve reached us after hours."
                value={officeHourSettingsState.outOfOfficeHeadline}
                onChange={(e): void => onOOOHeadlineChange(e.target.value)}
                isDisabled={!hasPermission}
                _placeholder={{ color: '#adb3b3' }}
              />
            </Flex>
            {officeHourSettingsState.outOfOfficeEnabled && (
              <Switch
                disabled={!hasPermission}
                name="outOfOfficeShowScheduleEnabled"
                label="Include Clinic Hours in out of office message"
                checked={officeHourSettingsState.outOfOfficeShowScheduleEnabled || false}
                onChange={(e, checked): void => onOOOSettingsToggle(e, checked)}
              />
            )}
          </>
          {hasPermission && (
            <Switch
              name="emergencyContactsEnabled"
              label="Enable Emergency Contact"
              disabled={!officeHourSettingsState.emergencyClinics.length}
              checked={officeHourSettingsState.emergencyContactsEnabled || false}
              onChange={(e, checked): void => onOOOSettingsToggle(e, checked)}
            />
          )}
          <Flex display={!officeHourSettingsState.emergencyContactsEnabled && !hasPermission ? 'none' : 'flex'}>
            {officeHourSettingsState.emergencyClinics?.map(
              (clinic: EmergencyClinicsFragment, index: number): JSX.Element => (
                <Draggable
                  hasPermission={hasPermission}
                  index={index}
                  id={clinic.id}
                  key={clinic.id}
                  onHover={handleEmergencyClinicHover}
                  onDrop={handleEmergencyClinicDrop}
                >
                  <Flex
                    w="300px"
                    minH="100px"
                    justifyContent="space-between"
                    background="background.default"
                    mr="18px"
                    padding="10px"
                    borderRadius="5px"
                  >
                    <Flex direction="column">
                      <Text size="md" fontWeight="bold">
                        {clinic.name}
                      </Text>
                      <Text whiteSpace="pre-line">
                        {clinic.fullAddress
                          ? clinic.fullAddress
                          : `${clinic.addressLine1} ${clinic.addressLine2}
                        ${clinic.city}, ${clinic.state}`}
                      </Text>
                      <Box>{formatPhoneNumber(clinic.phone)}</Box>
                    </Flex>
                    {hasPermission && (
                      <Dropdown
                        options={emergencyClinicMenuDropdownOptions}
                        placement={PopoverPlacement.RightEnd}
                        onSelect={partial(handleClinicMenuOptionClick, clinic)}
                      >
                        <MoreOptionsButton
                          onClick={(): void => {
                            Mixpanel.track('General Settings Emergency Contact Detail');
                          }}
                        />
                      </Dropdown>
                    )}
                  </Flex>
                </Draggable>
              ),
            )}
            {officeHourSettingsState.emergencyClinics &&
              officeHourSettingsState.emergencyClinics.length < 3 &&
              hasPermission && (
                <HStack
                  w="300px"
                  minH="100px"
                  justifyContent="center"
                  background="background.default"
                  mr="18px"
                  padding="10px"
                  fontSize={12}
                  borderRadius="5px"
                  onClick={(): void => editContact()}
                  border="dashed 1px #000"
                  cursor="pointer"
                >
                  <Icon name="plus" size="sm" />
                  <Text mr="5px">
                    Add {!!officeHourSettingsState.emergencyClinics.length && 'Additional'} Emergency Contact
                  </Text>
                </HStack>
              )}
          </Flex>
        </Box>
        <Divider mb={5} />
        <Text size="md" fontWeight="bold" mb={2}>
          Text Messaging
        </Text>
        <AutoResponseTextArea
          disabled={!hasPermission}
          name="smsAutoResponseWhenClosed"
          label="Out-Of-Office Response"
          description="This message is sent in response to every incoming text message while your clinic is closed. To disable this response, simply delete the text and save the changes."
          required={false}
          persistedValue={OOOResponseQuery?.findUniqueClinic?.clinicSetting?.smsAutoResponseWhenClosed}
          isLoading={false}
          ref={clinicHourSaveResRef}
          onOOOResponseChange={(OOOResponseChanged: boolean): void => onOOOResponseChange(OOOResponseChanged)}
        />
        <NotesContainer>
          <p>
            <b>Note:</b>{' '}
            {
              "Wildcards are not currently supported. The clinic's {{next open day}} and {{time}} tags will only be used in the default response."
            }
          </p>
        </NotesContainer>
      </>
    );
  },
);

export default ClinicHoursSettings;

const NotesContainer = styled.div`
  padding-bottom: 20px;
  b {
    font-weight: 600;
  }

  p {
    font-size: 14px;
    margin: 10px 0 0;
    max-width: 500px;
    line-height: 1.5;
  }
`;
