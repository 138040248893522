import React, { useCallback, useMemo } from 'react';
import { useGetClinicTagsQuery } from 'shared/types/graphql';
import { GraphQLFetchPolicies } from 'shared/enums/GraphQLFetchPolicies';
import useClinicUser from 'shared/hooks/useClinicUser';
import { Swatch } from 'shared/components/ConversationStatusSelect';
import { getTagVariantByType } from 'pages/Conversations/components/ChannelList/ChannelListItem/CardTags';
import { Menu, MenuItemProps } from '@televet/kibble-ui/build/components/Menu';
import { VStack, Wrap } from '@televet/kibble-ui/build/chakra';
import { ActionEffect, ActionEffectType } from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';
import { ChannelTagType, TagInput } from '@televet/shared-types';
import upperFirst from 'lodash-es/upperFirst';
import { Tag } from '@televet/kibble-ui/build/components/Tag';

interface ConversationTagMenuProps {
  selectedTags: TagInput[];
  onSelectedTagsChange: (actionEffects: ActionEffect[]) => void;
}

const ConversationTagMenu = ({ selectedTags, onSelectedTagsChange }: ConversationTagMenuProps): JSX.Element => {
  const { currentClinicId } = useClinicUser();

  const { data: clinicTagsData } = useGetClinicTagsQuery({
    fetchPolicy: GraphQLFetchPolicies.CacheFirst,
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
  });

  const allTags = useMemo(() => clinicTagsData?.findUniqueClinic?.clinicSetting?.tags?.tags || [], [clinicTagsData]);

  const tagMenuOptions: MenuItemProps[] = useMemo(() => {
    const options: MenuItemProps[] = allTags.map((tag: TagInput) => {
      const color =
        tag.tagType === ChannelTagType.Custom && tag.colorBackground
          ? tag.colorBackground
          : `background.${getTagVariantByType(tag.tagType)}`;

      return {
        value: tag.name,
        label: tag.name,
        leftElement: <Swatch bgColor={color} />,
        isSelected: selectedTags.some((selectedTag) => selectedTag.name === tag.name),
      } as MenuItemProps;
    });

    return options;
  }, [allTags, selectedTags]);

  const handleTagsChanged = useCallback(
    (selectedItem: MenuItemProps, selectedItems?: MenuItemProps[]) => {
      const tags =
        selectedItems?.map((item) => allTags.find((tag: TagInput) => tag.name === item.value)).filter(Boolean) || [];

      const actionEffects: ActionEffect[] = [
        {
          type: ActionEffectType.AddTagsToChannel,
          tags,
        },
      ];

      onSelectedTagsChange(actionEffects);
    },
    [allTags, onSelectedTagsChange],
  );

  const removeTag = useCallback(
    (tagName: string) => {
      const newTags = selectedTags.filter((tag) => tag.name !== tagName);

      const actionEffects: ActionEffect[] = [
        {
          type: ActionEffectType.AddTagsToChannel,
          tags: newTags,
        },
      ];

      onSelectedTagsChange(actionEffects);
    },
    [onSelectedTagsChange, selectedTags],
  );

  return (
    <VStack alignItems="flex-start">
      <Menu
        buttonProps={{
          text: 'Add',
          leftIconName: 'plus',
          showRightIcon: false,
          size: 'md',
          variant: 'secondarySubtle',
          w: 'fit-content',
        }}
        listProps={{
          isMultiSelect: true,
          menuItems: tagMenuOptions,
          onSelect: handleTagsChanged,
        }}
      />
      <Wrap pt={2}>
        {selectedTags.map((tag) => (
          <Tag
            key={`channelTag-${tag.name}`}
            size="md"
            label={upperFirst(tag.name)}
            closeButtonDisplay="always"
            {...(tag.tagType === ChannelTagType.Custom && tag.colorBackground && tag.colorText
              ? { bg: tag.colorBackground, color: tag.colorText }
              : { variant: getTagVariantByType(tag.tagType) })}
            onClose={(): void => removeTag(tag.name)}
          />
        ))}
      </Wrap>
    </VStack>
  );
};

export default ConversationTagMenu;
