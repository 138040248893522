export const downloadAndRenameFile = async (url: string, fileName: string): Promise<void> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Network response was not ok - ${url}`);
  }

  const blob = await response.blob();
  const newUrl = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = newUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a); // Clean up the DOM
};
