import { useMemo, useCallback, useEffect } from 'react';
import UAParser from 'ua-parser-js';
import { DEFAULT_AUDIO_URL } from 'shared/utils/sounds';
import useClinicUser from 'shared/hooks/useClinicUser';
import useBrowserNotifications, { ICreateNotification } from 'shared/hooks/useBrowserNotifications';
import useUnreadMessageCount from 'shared/hooks/useUnreadMessageCount';
import {
  ChannelStatusAction,
  ChannelType,
  MessageType,
  MutationType,
  SubscriptionChannelMessageFragment,
  useSubscribeToClinicUserChannelMessagesSubscription,
  useSubscribeToUnreadMessageCountChangedSubscription,
} from 'shared/types/graphql';
import { useInAppNotificationsProvider } from 'shared/providers/InAppNotificationsProvider';
import { getAttachmentPreviewLabel } from 'pages/Conversations/utils';
import { TwilioIdentity } from '@televet/shared-types/twilio';
import { RouteBasePaths } from 'routes';
import { cache } from 'shared/providers/ApolloProvider/cache';
import { Browsers } from 'shared/enums/Browsers';

const audio = new Audio(DEFAULT_AUDIO_URL);

// TODO: Add channelStatus filter to subscription resolver
const useInAppNotifications = (): void => {
  const { clinicUser, currentClinicId } = useClinicUser();
  const userId = clinicUser?.id;
  const userSetting = clinicUser?.userSetting;
  const { createNotification } = useBrowserNotifications();
  const { excludedChannelIds } = useInAppNotificationsProvider();
  const { getBrowser } = new UAParser();
  const browser: string = getBrowser().name || '';

  const isBrowserNotificationEnabled = useMemo(
    () => clinicUser?.userSetting?.notificationSettings?.some((s) => Boolean(s.browserNotificationEnabled)),
    [clinicUser],
  );

  const shouldSendNotification = useMemo(() => {
    if (!('Notification' in window)) return false;

    return isBrowserNotificationEnabled && Notification.permission === 'granted';
  }, [isBrowserNotificationEnabled]);

  const sendNotification = useCallback(
    (options: ICreateNotification) => {
      if (!shouldSendNotification) return;
      try {
        createNotification(options);

        if (browser !== Browsers.Safari) {
          audio.play().catch((error) => {
            console.warn(error);
          });
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [shouldSendNotification, createNotification, browser],
  );

  const sendMessageNotification = useCallback(
    ({
      channelId,
      message,
      isTeamChat,
    }: {
      channelId: string;
      message: SubscriptionChannelMessageFragment;
      isTeamChat: boolean;
    }) => {
      const author = message.author?.user || message.author?.clinicPetParent;
      const [attachment] = message.attachments || [];
      const body = message.body
        ? message.body
        : attachment
        ? getAttachmentPreviewLabel(attachment.attachmentType)
        : '(Unknown)';
      sendNotification({
        title: `Message from ${[author?.firstName, author?.lastName].join(' ').trim()}`,
        body,
        clickRedirectPath: `${isTeamChat ? RouteBasePaths.Team : RouteBasePaths.Conversations}/${channelId}`,
      });
    },
    [sendNotification],
  );

  const { refreshData } = useUnreadMessageCount();

  /**
   * Refresh notification count every 3 minutes at a minimum
   */
  useEffect(() => {
    const THREE_MINUTES_IN_MS = 1000 * 60 * 3;
    const interval = setInterval(() => {
      refreshData();
    }, THREE_MINUTES_IN_MS);
    return (): void => clearInterval(interval);
  }, [refreshData]);

  useSubscribeToClinicUserChannelMessagesSubscription({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId,
    onData: ({ data }) => {
      const message = data.data?.channelMessageChanged?.node;
      const channelId = message?.channel?.id;

      if (!message || !channelId || !userSetting) return;
      if (data?.data?.channelMessageChanged?.mutation === MutationType.Delete) {
        cache.evict({
          id: cache.identify(message),
        });
        cache.gc();
        return;
      }
      const assignees = message.channel?.assignees || [];
      const isChannelUnassigned = !assignees.length;
      const isChannelAssignedToCurrentUser = assignees.some(({ id }) => id === userId);

      /**
       * Determine whether to send a browser/sound notification based on the
       * user's notification settings and message properties
       */
      const isMessageFromPetParent = message.messageType === MessageType.Message && !!message.author?.clinicPetParent;
      const isSystemMessage = message.author?.twilioIdentity === TwilioIdentity.System;
      const isTeamChat = message.channel?.channelType === ChannelType.Team;
      const isAssignedTeamChat = isTeamChat && isChannelAssignedToCurrentUser;

      let shouldSendNotification =
        (isMessageFromPetParent || isSystemMessage || message.messageType === MessageType.Note || isAssignedTeamChat) &&
        !excludedChannelIds.includes(channelId) &&
        message.channel?.channelStatus?.channelStatusAction !== ChannelStatusAction.InactivePermanently;

      if (userSetting.browserNotificationFilter && shouldSendNotification) {
        const { assignees: users, includeUnassigned } = userSetting.browserNotificationFilter;
        if (isChannelUnassigned) {
          shouldSendNotification = Boolean(includeUnassigned);
        } else if (isChannelAssignedToCurrentUser && users) {
          shouldSendNotification = users.some(({ id }) => id === userId);
        } else {
          shouldSendNotification = false;
        }
      }

      if (shouldSendNotification) {
        refreshData();
        sendMessageNotification({
          channelId,
          message,
          isTeamChat,
        });
      }
    },
  });

  useSubscribeToUnreadMessageCountChangedSubscription({
    variables: { clinicId: currentClinicId || '' },
    skip: !currentClinicId || !userId,
    onData: () => {
      refreshData();
    },
  });
};

export default useInAppNotifications;
