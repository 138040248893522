import {
  Button,
  Popover,
  Tag,
  Box,
  Spinner,
  HStack,
  Text,
  VStack,
  useToast,
  Tooltip,
  Heading,
} from '@televet/kibble-ui';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { GraphQLFetchPolicies } from 'shared/enums';
import { GetConversationsQuickReplyQuery, useGetConversationsQuickReplyLazyQuery } from 'shared/types/graphql';
import { Mixpanel } from 'shared/utils';

const NO_SUGGESTION_AVAILABLE = 'No suggested response available';

interface QuickReplyProps {
  channelId: string | undefined;
  setDraftMessageText: Dispatch<SetStateAction<string>>;
  discardSuggestion: (text: string) => void;
  setSelectedSuggestion: (text: string) => void;
  addSuggestion: (text: string) => Promise<void>;
}

const QuickReplyPopover = ({
  channelId,
  setDraftMessageText,
  addSuggestion,
  discardSuggestion,
  setSelectedSuggestion,
}: QuickReplyProps): JSX.Element => {
  const toast = useToast();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const getQuickReplyMessage = useCallback((data?: GetConversationsQuickReplyQuery) => {
    const suggestedResponse = data?.aiChannelSuggestedMessage?.parsedJson?.suggestedResponse;

    if (!suggestedResponse) return NO_SUGGESTION_AVAILABLE;

    // The JSON response from the end point may not always be the same structure
    // We should at some point update the prompt on the backend to specify
    // the shape of the data to make this more predictable
    return suggestedResponse.body || suggestedResponse.text || suggestedResponse;
  }, []);

  const [getQuickReply, { data: quickReplyData, loading: quickReplyDataIsLoading, refetch: refetchQuickReplyData }] =
    useGetConversationsQuickReplyLazyQuery({
      notifyOnNetworkStatusChange: true,
      fetchPolicy: GraphQLFetchPolicies.NoCache,
      onCompleted: (data) => {
        const message = getQuickReplyMessage(data);
        if (message !== NO_SUGGESTION_AVAILABLE) {
          addSuggestion(message);
        }
      },
    });

  const quickReplyMessage = useMemo(() => {
    return getQuickReplyMessage(quickReplyData);
  }, [getQuickReplyMessage, quickReplyData]);

  const refreshSuggestion = useCallback(async () => {
    discardSuggestion(quickReplyMessage);

    try {
      await refetchQuickReplyData();
    } catch (e) {
      toast({
        status: 'error',
        description: 'Unable to refresh suggested messages at this time. Please try again later.',
      });
    }
  }, [quickReplyMessage, discardSuggestion, refetchQuickReplyData, toast]);

  const launchQuickReplyPopover = useCallback(async () => {
    if (!channelId) return;

    Mixpanel.track('Quick reply popover opened');

    setIsPopoverOpen(true);

    try {
      await getQuickReply({
        variables: {
          where: {
            channelId,
          },
        },
      });
    } catch (e) {
      toast({
        status: 'error',
        description: 'Unable to fetch suggested messages at this time. Please try again later.',
      });
    }
  }, [getQuickReply, channelId, toast]);

  const insertQuickReply = useCallback(() => {
    Mixpanel.track('Quick reply message inserted in message composer');
    setSelectedSuggestion(quickReplyMessage);
    setDraftMessageText(quickReplyMessage);
    setIsPopoverOpen(false);
  }, [quickReplyMessage, setDraftMessageText, setSelectedSuggestion]);

  // Close popover when changing channels
  useEffect(() => {
    setIsPopoverOpen(false);
  }, [channelId]);

  const onClose = (): void => {
    discardSuggestion(quickReplyMessage);
    setIsPopoverOpen(false);
  };

  return (
    <Popover
      size="md"
      variant="default"
      placement="top"
      offset={[-110, 0]}
      isOpen={isPopoverOpen}
      isLazy
      headerProps={{
        children: (
          <HStack className="QuickReply__Header" align="center">
            <Heading size="sm">Quick Reply</Heading>
            <Tag size="sm" label="Beta" variant="info" />
          </HStack>
        ),
        onClose,
      }}
      anchor={
        <Box className="MessageComposer__QuickReplyButton" pos="relative">
          <Tooltip isDisabled={isPopoverOpen} label="Quick Reply">
            <Button mt={1} mr={1} onClick={launchQuickReplyPopover} variant="ghost" iconName="magic" />
          </Tooltip>
        </Box>
      }
    >
      <Box className="QuickReply__MessageContainer" p={3} borderRadius="lg" bg="background.subtle">
        {quickReplyDataIsLoading ? (
          <VStack className="QuickReply__MessageContainer-Loading" p={3} align="center" justify="center">
            <Spinner />
            <Text size="xs" variant="subtle">
              Analyzing the conversation data
            </Text>
          </VStack>
        ) : (
          <>
            <Text size="sm" className="QuickReply__MessageContainer-Text">
              {quickReplyMessage}
            </Text>
          </>
        )}
      </Box>
      <HStack justify="space-between" mt={4}>
        <Button className="QuickReply__MessageContainer-CancelButton" onClick={onClose} variant="tertiary" size="xs">
          Cancel
        </Button>
        <HStack justify="flex-end">
          <Button
            className="QuickReply__MessageContainer-RefreshButton"
            onClick={refreshSuggestion}
            variant="ghost"
            size="xs"
            iconName="arrowCircle"
            isDisabled={quickReplyDataIsLoading}
          >
            Try Again
          </Button>
          <Button
            className="QuickReply__MessageContainer-Submit"
            onClick={insertQuickReply}
            size="xs"
            isDisabled={quickReplyDataIsLoading}
          >
            Insert
          </Button>
        </HStack>
      </HStack>
    </Popover>
  );
};

export default QuickReplyPopover;
