import React, { useCallback, FocusEvent, useMemo } from 'react';
import { AutomationsPromptBlockFieldContainer } from 'pages/Automations/components/Layout';
import { useAppDispatch } from 'state/hooks';
import { updateAutomationPromptConfig, updateAutomationPromptErrors } from 'pages/Automations/state/automationsSlice';
import { Divider, HStack } from '@televet/kibble-ui/build/chakra';
import { TextInput } from '@televet/kibble-ui/build/components/TextInput';
import { StatusChangeSelect } from './StatusChangeSelect';
import { NextStepSelect } from '../PromptFields';
import { PromptInvoiceError, PromptNextStepError } from 'pages/Automations/types/AutomationValidation';
import { useGetFieldErrorText } from 'pages/Automations/hooks/useGetFieldErrorText';
import { AutomationRunActionPromptType } from 'shared/types/graphql';
import { WorkflowEventActionConfig } from '@televet/shared-types/JsonTypes/WorkflowEventActionConfig';

interface InvoiceAmountPrompts {
  promptType: AutomationRunActionPromptType;
  config: WorkflowEventActionConfig;
  triggerActionNumber: number | null;
  promptIndex: number;
  errors: {
    invoiceErrors?: PromptInvoiceError[];
    nextStepErrors?: PromptNextStepError[];
  };
}

export const InvoiceAmount = ({
  promptType,
  config,
  promptIndex,
  errors: { invoiceErrors },
}: InvoiceAmountPrompts): JSX.Element => {
  const dispatch = useAppDispatch();
  const errorText = useGetFieldErrorText(invoiceErrors);

  const fieldIsInvalid = useMemo(() => {
    return Boolean(invoiceErrors?.length);
  }, [invoiceErrors]);

  const { invoiceAmount, paymentChannelStatusChange } = config;

  const handleInvoiceAmountChange = useCallback(
    (value: string) => {
      dispatch(updateAutomationPromptConfig({ promptIndex, config: { invoiceAmount: value } }));

      if (fieldIsInvalid) {
        dispatch(updateAutomationPromptErrors({ promptIndex, prompt: { invoice: [] } }));
      }
    },
    [promptIndex, fieldIsInvalid, dispatch],
  );

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      // Invoice amount is stored as an Int without decimals. (Example: 1234.56 is stored as 123456)
      // When entering an invoice amount in the ui, if no decimal is used, ".00" is always appended to the value
      // This ensures that the last two digits stored in this value will always be able to be converted to cents.
      const formattedValue = Number(event.target.value).toFixed(2);
      handleInvoiceAmountChange(formattedValue);
    },
    [handleInvoiceAmountChange],
  );

  return (
    <>
      <AutomationsPromptBlockFieldContainer label="Invoice Amount">
        <TextInput
          type="number"
          inputMode="decimal"
          placeholder="0.00"
          errorText={errorText}
          isInvalid={fieldIsInvalid}
          value={invoiceAmount || ''}
          onChange={(e): void => handleInvoiceAmountChange(e.target.value)}
          onBlur={handleInputBlur}
        />
      </AutomationsPromptBlockFieldContainer>
      <AutomationsPromptBlockFieldContainer>
        <Divider mb={6} />
        <HStack alignItems="flex-start">
          <StatusChangeSelect
            promptType={promptType}
            promptIndex={promptIndex}
            channelStatusChangeId={paymentChannelStatusChange}
          />
          <NextStepSelect promptIndex={promptIndex} />
        </HStack>
      </AutomationsPromptBlockFieldContainer>
    </>
  );
};
