import { NextStepSelectValue } from '../types/NextStepSelectValue';

export const getStepValueFromConfig = ({
  actionNumber,
  workflowId,
  isStatementPrompt,
  externalUrl,
  sendButtonMessage,
}: {
  actionNumber: number | null | undefined;
  workflowId: string | null | undefined;
  isStatementPrompt: boolean;
  externalUrl: string | undefined;
  sendButtonMessage?: boolean;
}): NextStepSelectValue | string => {
  let value: string = NextStepSelectValue.NextPrompt;

  // If prompt type is statement, it should always be the final step in the automation.
  if (isStatementPrompt) {
    value = NextStepSelectValue.EndAutomation;
  } else if (externalUrl !== undefined) {
    value = NextStepSelectValue.OpenLink;
  } else {
    if (actionNumber) {
      value = NextStepSelectValue.NextPrompt;
    }

    if (workflowId) {
      value = workflowId;
    }

    if (!actionNumber && !workflowId) {
      value = NextStepSelectValue.EndAutomation;
    }

    if (sendButtonMessage) {
      value = NextStepSelectValue.SendAMessage;
    }
  }

  return value;
};
