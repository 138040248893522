import React, { useCallback, useMemo } from 'react';
import { Icon, Popover, Text, Flex } from '@televet/kibble-ui';
import { ConversationStatusSelect } from 'shared/components/ConversationStatusSelect';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  updateAutomationPrompt,
  updateAutomationPromptButton,
  updateAutomationPromptConfig,
} from 'pages/Automations/state/automationsSlice';
import { AutomationRunActionPromptType } from 'shared/types/graphql';
import { FormControlSize } from '@televet/kibble-ui/build/shared/types/formControl';

interface StatusChangeSelectProps {
  size?: FormControlSize;
  promptType: AutomationRunActionPromptType;
  promptIndex: number;
  channelStatusChangeId?: string | null;
  buttonIndex?: number;
  isInitialStatusChange?: boolean;
  showLabel?: boolean;
  buttonWidth?: string;
}

export const StatusChangeSelect = ({
  size = 'md',
  promptType,
  promptIndex,
  channelStatusChangeId,
  buttonIndex,
  isInitialStatusChange = false,
  showLabel = true,
  buttonWidth,
}: StatusChangeSelectProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const isGlobalEditor = useAppSelector((state) => state.automations.isGlobalEditor);

  const isPaymentPrompt = useMemo(() => {
    return promptType === AutomationRunActionPromptType.Payment;
  }, [promptType]);

  const isButtonPrompt = useMemo(() => {
    return promptType === AutomationRunActionPromptType.Buttons;
  }, [promptType]);

  const label = useMemo(() => {
    if (isInitialStatusChange) {
      return (
        <Flex align="center">
          <Text>Initial Status Change</Text>
          <Popover
            placement="right"
            size="lg"
            trigger="hover"
            anchor={
              <Flex cursor="pointer" ml="1">
                <Icon name="infoCircle" size="xs" variant="subtle" />
              </Flex>
            }
          >
            This status change will occur when the prompt appears. Any status changes from previous prompts may be
            overwritten.
          </Popover>
        </Flex>
      );
    }
    if (isPaymentPrompt) {
      return 'Status Change When Paid';
    }
    return 'Status Change';
  }, [isPaymentPrompt, isInitialStatusChange]);

  const handleStatusChange = useCallback(
    (channelStatusChangeId: string | null) => {
      if (isInitialStatusChange) {
        dispatch(updateAutomationPrompt({ promptIndex, prompt: { channelStatusChangeId } }));
      } else {
        if (isPaymentPrompt) {
          dispatch(
            updateAutomationPromptConfig({
              promptIndex,
              config: { paymentChannelStatusChange: channelStatusChangeId || undefined },
            }),
          );
        } else if (isButtonPrompt && buttonIndex !== undefined) {
          dispatch(
            updateAutomationPromptButton({
              promptIndex,
              buttonIndex,
              button: {
                channelStatusChangeId,
              },
            }),
          );
        }
      }
    },
    [buttonIndex, promptIndex, isButtonPrompt, isPaymentPrompt, isInitialStatusChange, dispatch],
  );

  return !isGlobalEditor ? (
    <ConversationStatusSelect
      size={size}
      label={showLabel ? label : undefined}
      value={channelStatusChangeId}
      onStatusChange={handleStatusChange}
      menuHeight="300px"
      buttonWidth={buttonWidth}
    />
  ) : null;
};
